import Component from 'lib/component';
import ErrorAlert from 'components/error-alert';
import style from './main.css';
import TrackService, { Track } from '../services/track';
import CurrentTrack from '../components/current-track';
import Loader from '../components/loader';

export default class Main extends Component {
  public errorAlert = new ErrorAlert();
  public content = new Component();
  public loader = new Loader();
  public currentTrack: Track = {
    artist: '',
    title: '',
    cover: '',
    buyLink: '',
  };

  private displayBuyLink = true;

  public constructor(slug: string, buy: string) {
    super({ className: style.mainView });

    if (!slug) {
      this.setupErrorView();
    } else {
      this.displayBuyLink = buy !== '0';
      setInterval(() => {
        this.fetchCurrentTrack(slug);
      }, 30000);
      this.fetchCurrentTrack(slug);
      this.setupViews();
    }
  }

  private setupViews(): void {
    this.errorAlert.hide();
    this.add(this.loader);
    this.add(this.errorAlert);
    this.add(this.content);
  }

  private setupErrorView(): void {
    this.loader.hide();
    this.errorAlert.show();
    this.add(this.errorAlert);
  }

  private fetchCurrentTrack(id: string): void {
    const trackService = new TrackService();
    const rez = trackService.fetchCurrentTrack(id);
    rez.subscribe(this.displayCurrentTrack.bind(this), () =>
      this.setupErrorView(),
    );
  }

  private displayCurrentTrack(result: Track): void {
    this.loader.hide();
    if (
      this.currentTrack &&
      this.currentTrack.artist === result.artist &&
      this.currentTrack.title === result.title
    ) {
      return;
    }
    this.currentTrack = result;
    this.content.clear();
    this.content.add(new CurrentTrack(result, this.displayBuyLink));
  }
}
