import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextXHRBackend from 'i18next-xhr-backend';
import Component from 'lib/component';
import { tif } from 'lib/template-operators';
import { Track } from 'services/track';
import translations from 'i18n/translations';
import svg from 'lib/svgs';
import style from './current-track.css';

export default class CurrentTrack extends Component {
  private displayBuyLink: boolean;
  public constructor(content: Track, displayBuyLink: boolean) {
    super({ className: style.articleCard });

    this.displayBuyLink = displayBuyLink;
    i18next
      .use(LanguageDetector)
      .use(i18nextXHRBackend)
      .init(
        {
          fallbackLng: 'en',
          debug: false,
          resources: translations,
        },
        () => {
          this.init(content);
        },
      );
  }

  init(track: Track): void {
    let html = `
      ${tif(track.cover)(`
          <img src='${track.cover}' alt='${track.title}' class='${style.image}' />
      `)}
      ${tif(!track.cover)(`<span class="${style.image}">${svg.cover}</span>`)}
      <div class="${style.infos}"><div class="${style.titleBlock}">
      <div class='${style.title}'>${track.title}</div>
      ${tif(track.artist)(`<div class='${style.artist}'>${track.artist}</div>`)}
      </div></div>
    `;
    if (this.displayBuyLink && track.buyLink) {
      html += `<div class="${style.buy}"><a target="_blank" href="${
        track.buyLink
      }"><span class="${style.iconButton}">${svg.cart}</span>${i18next.t(
        'BUY',
      )}</a></div>`;
    }

    this.updateHtml(html);
  }
}
