import { fromFetch } from 'rxjs/fetch';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { getJson } from '../lib/operators';
import config from '../config';

interface ApiCurrentTrack {
  title: string;
  artist: string;
  cover: string;
  buy_link: string;
}

export interface Track {
  title: string;
  artist: string;
  cover: string;
  buyLink: string;
}

export default class TrackService {
  public url: string;
  public constructor() {
    const { widget } = config.rkApi;
    this.url = `${widget}/radio/`;
  }

  public fetchCurrentTrack = (idRadio: string): Observable<Track> => {
    const url = `${this.url}${idRadio}/track/current`;
    return fromFetch(url).pipe(
      getJson<ApiCurrentTrack>(),
      map(data => {
        if (!data.title) {
          throw new Error();
        }
        return TrackService.convertToTrack(data);
      }),
      catchError(err => {
        return throwError(err);
      }),
    );
  };

  static convertToTrack(data: ApiCurrentTrack): Track {
    return {
      title: data.title,
      artist: data.artist,
      cover: data.cover,
      buyLink: data.buy_link,
    };
  }
}
