import Component from 'lib/component';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextXHRBackend from 'i18next-xhr-backend';
import style from './current-track.css';

export default class ErrorAlert extends Component {
  public constructor() {
    super({ className: style.articleCard });
    i18next
      .use(LanguageDetector)
      .use(i18nextXHRBackend)
      .init(
        {
          fallbackLng: 'en',
          debug: false,
          resources: {
            en: {
              translation: {
                NO_DATA: 'No data.',
              },
            },
            fr: {
              translation: {
                NO_DATA: 'Aucune donnée.',
              },
            },
            de: {
              translation: {
                NO_DATA: 'Keine Daten.',
              },
            },
            es: {
              translation: {
                NO_DATA: 'No hay datos.',
              },
            },
            it: {
              translation: {
                NO_DATA: 'Nessun dato.',
              },
            },
            pt: {
              NO_DATA: 'Sem dados.',
            },
          },
        },
        () => {
          this.updateText(i18next.t('NO_DATA'));
        },
      );
  }
}
